/* iCheck plugin Square skin, green
----------------------------------- */
.icheckbox_square-green,
.iradio_square-green {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;
    background: url(../img/green.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_square-green {
    background-position: 0 0;
}
.icheckbox_square-green.hover {
    background-position: -24px 0;
}
.icheckbox_square-green.checked {
    background-position: -48px 0;
}
.icheckbox_square-green.disabled {
    background-position: -72px 0;
    cursor: default;
}
.icheckbox_square-green.checked.disabled {
    background-position: -96px 0;
}

.iradio_square-green {
    margin-right: 6px;
    background-position: -120px 0;
}
.iradio_square-green.hover {
    background-position: -144px 0;
}
.iradio_square-green.checked {
    background-position: -168px 0;
}
.iradio_square-green.disabled {
    background-position: -192px 0;
    cursor: default;
}
.iradio_square-green.checked.disabled {
    background-position: -216px 0;
}

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    .icheckbox_square-green,
    .iradio_square-green {
        background-image: url(../img/green@2x.png);
        -webkit-background-size: 240px 24px;
        background-size: 240px 24px;
    }
}

/.icheckbox_minimal-green,
 .iradio_minimal-green {
     display: inline-block;
     *display: inline;
     vertical-align: middle;
     margin: 0;
     padding: 0;
     width: 18px;
     height: 18px;
     background: url(../img/green.png) no-repeat;
     border: none;
     cursor: pointer;
 }

.icheckbox_minimal-green {
    background-position: 0 0;
}
.icheckbox_minimal-green.hover {
    background-position: -20px 0;
}
.icheckbox_minimal-green.checked {
    background-position: -40px 0;
}
.icheckbox_minimal-green.disabled {
    background-position: -60px 0;
    cursor: default;
}
.icheckbox_minimal-green.checked.disabled {
    background-position: -80px 0;
}

.iradio_minimal-green {
    background-position: -100px 0;
}
.iradio_minimal-green.hover {
    background-position: -120px 0;
}
.iradio_minimal-green.checked {
    background-position: -140px 0;
}
.iradio_minimal-green.disabled {
    background-position: -160px 0;
    cursor: default;
}
.iradio_minimal-green.checked.disabled {
    background-position: -180px 0;
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5) {
    .icheckbox_minimal-green,
    .iradio_minimal-green {
        background-image: url(../img/green@2x.png);
        -webkit-background-size: 200px 20px;
        background-size: 200px 20px;
    }
}


/* green */
.icheckbox_minimal-green,
.iradio_minimal-green {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 18px;
    height: 18px;
    background: url(../img/light_green.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_minimal-green {
    background-position: 0 0;
}
.icheckbox_minimal-green.hover {
    background-position: -20px 0;
}
.icheckbox_minimal-green.checked {
    background-position: -40px 0;
}
.icheckbox_minimal-green.disabled {
    background-position: -60px 0;
    cursor: default;
}
.icheckbox_minimal-green.checked.disabled {
    background-position: -80px 0;
}

.iradio_minimal-green {
    background-position: -100px 0;
}
.iradio_minimal-green.hover {
    background-position: -120px 0;
}
.iradio_minimal-green.checked {
    background-position: -140px 0;
}
.iradio_minimal-green.disabled {
    background-position: -160px 0;
    cursor: default;
}
.iradio_minimal-green.checked.disabled {
    background-position: -180px 0;
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5) {
    .icheckbox_minimal-green,
    .iradio_minimal-green {
        background-image: url(../img/light_green@2x.png);
        -webkit-background-size: 200px 20px;
        background-size: 200px 20px;
    }
}